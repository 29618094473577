@use "../helpers/variables" as var;
@import "../helpers/animations";

.section--gallery {
  background-color: var.$clr--secondary;
}

.gallery {
  display: grid;
  grid-template-areas:
    "one one two three"
    "one one two four"
    "five six six four"
    "seven eight nine nine"
    "ten eight nine nine"
    "ten eleven twelve twelve"
    "thirteen eleven twelve twelve";
  gap: 1rem;
  padding: 0.5rem;
}

.gallery__image {
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    transition: 0.2s ease-in-out;
    animation: blur 1.25s ease-in-out;

    &:hover {
      transform: scale(1.08);
      filter: none;
    }
  }
}

.photos {
  color: var.$clr--primary;
  font-family: var.$font--content;
  font-weight: var.$font--600;
  font-size: clamp(1rem, 3vw, 2rem);
  padding: 2rem;
  text-align: center;

  a {
    color: var.$clr--primary;
    margin: 0.5rem;
    transition: 0.25s;

    &:hover {
      color: white;
    }

    svg {
      margin-right: 0.5rem;
    }
  }
}

@media (max-width: 1200px) {
  .gallery {
    grid-template-areas:
      "one one two"
      "one one two"
      "three six six"
      "four six six"
      "four five seven"
      "four five eight"
      "nine nine eight"
      "eleven thirteen thirteen"
      "ten thirteen thirteen"
      "ten twelve twelve";
  }
}

@media (max-width: 600px) {
  .gallery {
    grid-template-areas:
      "one"
      "two"
      "three"
      "four"
      "five"
      "six"
      "seven"
      "eight"
      "nine"
      "ten"
      "twelve"
      "eleven"
      "thirteen";
  }
}

.gallery__image:nth-child(1) {
  grid-area: one;
}
.gallery__image:nth-child(2) {
  grid-area: two;
}
.gallery__image:nth-child(3) {
  grid-area: three;
}
.gallery__image:nth-child(4) {
  grid-area: four;
}
.gallery__image:nth-child(5) {
  grid-area: five;
}
.gallery__image:nth-child(6) {
  grid-area: six;
}
.gallery__image:nth-child(7) {
  grid-area: seven;
}
.gallery__image:nth-child(8) {
  grid-area: eight;
}
.gallery__image:nth-child(9) {
  grid-area: nine;
}
.gallery__image:nth-child(10) {
  grid-area: ten;
}
.gallery__image:nth-child(11) {
  grid-area: eleven;
}
.gallery__image:nth-child(12) {
  grid-area: twelve;
}
.gallery__image:nth-child(13) {
  grid-area: thirteen;
}
