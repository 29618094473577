@use "../helpers/variables" as var;
@import "../helpers/animations";

.section--contact {
  background-color: var.$clr--secondary;
  color: var.$clr--primary;
  font-family: var.$font--content;
  padding: 0.5rem;
}

.contact__logo {
  display: grid;
  place-items: center;
  animation: appear 1s ease-in;
  img {
    max-width: 275px;
    position: relative;
    z-index: 999;
  }
}

.contact__logo::before,
.contact__logo::after {
  content: "";
  position: absolute;
  width: 45%;
  height: 5px;
  background-color: var.$clr--primary;
}

.contact__logo::before {
  transform: translateX(50%);
  animation: slideFromLeft 0.875s ease-in;
}

.contact__logo::after {
  transform: translateX(-50%);
  animation: slideFromRight 0.875s ease-in;
}

.contact__container {
  display: flex;
  justify-content: space-around;
}

.hours,
.links {
  animation: slideFromBottomOpacity 0.75s ease-in;
}

.hours {
  font-size: clamp(1.25rem, 3vw, 1.75rem);
  border-bottom: 1px solid var.$clr--accent;
  padding-bottom: 2rem;

  h1,
  .hours__table {
    text-align: center;
    margin: 0 auto;
  }

  h1 {
    color: var.$clr--accent;
    margin-bottom: 0.5rem;
  }

  .hours__table {
    width: 500px;
    line-height: 2rem;
    font-weight: var.$font--600;
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.links {
  width: 100%;
  border-bottom: 1px solid var.$clr--accent;
  font-weight: var.$font--600;

  .fa-icon--contact {
    font-size: clamp(2.5rem, 3vw, 3rem);
    filter: grayscale(1);
    transition: 0.25s;
  }

  a {
    width: max-content;
    color: var.$clr--primary;
    display: block;
    margin-bottom: 1.25rem;
    font-size: clamp(1.75rem, 3vw, 2rem);
    cursor: pointer;
    transition: 0.25s;

    svg {
      margin-right: 1rem;
    }

    &:hover {
      color: white;
    }

    &:hover svg {
      filter: grayscale(0);
    }
  }
}

.icon--instagram {
  color: rgb(193, 53, 132);
}

.icon--facebook {
  color: rgb(59, 89, 152);
}

.icon--phone {
  color: var.$clr--accent;
}

.map {
  animation: slideFromBottomOpacity 0.75s ease-in;
  h2 {
    font-size: clamp(2.25rem, 3vw, 2.5rem);
    color: var.$clr--accent;
    text-align: center;
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 1200px) {
  .contact__container {
    flex-direction: column;
  }

  .links {
    border-bottom: none;
    a {
      margin: 0 auto 1.25rem auto;
    }
  }

  .map {
    margin-top: 1.5rem;
    text-align: center;

    iframe {
      width: 90%;
      margin: 0 auto;
    }
  }

  .hours .hours__table {
    width: auto;

    td {
      display: block;
    }
  }
}
