$clr--primary: rgb(200, 200, 200);
$clr--secondary: rgb(30, 30, 30);
$clr--accent: rgba(174, 116, 3, 255);

$font--header: "Arvo", serif;
$font--content: "Lato", sans-serif;
$font--description: "Secular One", sans-serif;
$font--100: 100;
$font--300: 300;
$font--500: 500;
$font--400: 400;
$font--600: 600;
