@use "../helpers/variables" as var;

.header__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: transparent;
  padding: 3rem 2rem;
  gap: 1.75rem;

  li {
    position: relative;
    font-size: clamp(1.25rem, 3vw, 2rem);
    font-family: var.$font--header;
    font-weight: var.$font--600;
    text-transform: uppercase;
    list-style: none;
    cursor: pointer;
    color: var.$clr--primary;
    transition: 0.3s ease-out;

    &::after {
      content: "";
      position: absolute;
      background-color: white;
      height: 3px;
      width: 0%;
      bottom: 0;
      right: 0;
      transition: width 0.25s ease-in-out;
    }

    &:hover {
      color: rgb(255, 255, 255);

      &::after {
        width: 100%;
        left: 0;
      }
    }
  }

  .section--active {
    color: white;
    text-shadow: 0 0 7px orange, 0 0 10px orange, 0 0 21px orange,
      0 0 42px goldenrod;
  }
}
