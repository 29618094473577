@import "./src/styles/pages/home";
@import "./src/styles/pages/delivery";
@import "./src/styles/pages/gallery";
@import "./src/styles/pages/contact";
@import "./src/styles/pages/menu";
@import "./src/styles/components/header";
@import "./src/styles/helpers/faIcons";

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

section {
  min-height: 100vh;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}
