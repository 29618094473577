@-webkit-keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes slideFromLeft {
  from {
    transform: translateX(-100%) translateY(3px);
  }
  to {
    transform: translateX(50%) translateY(3px);
  }
}
@keyframes slideFromLeft {
  from {
    transform: translateX(-100%) translateY(3px);
  }
  to {
    transform: translateX(50%) translateY(3px);
  }
}
@-webkit-keyframes slideFromRight {
  from {
    transform: translateX(100%) translateY(-3px);
  }
  to {
    transform: translateX(-50%) translateY(-3px);
  }
}
@keyframes slideFromRight {
  from {
    transform: translateX(100%) translateY(-3px);
  }
  to {
    transform: translateX(-50%) translateY(-3px);
  }
}
@-webkit-keyframes slideFromBottomOpacity {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes slideFromBottomOpacity {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
@-webkit-keyframes blur {
  from {
    filter: blur(15px);
  }
  to {
    filter: blur(0px);
  }
}
@keyframes blur {
  from {
    filter: blur(15px);
  }
  to {
    filter: blur(0px);
  }
}
@-webkit-keyframes deliverySlideLeft {
  from {
    transform: translateX(50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes deliverySlideLeft {
  from {
    transform: translateX(50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@-webkit-keyframes deliverySlideRight {
  from {
    transform: translateX(-300%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes deliverySlideRight {
  from {
    transform: translateX(-300%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.section--home {
  background-image: linear-gradient(rgba(30, 30, 30, 0.8), rgba(30, 30, 30, 0.85)), url("images/homeBg.jpg");
  background-size: cover;
  display: flex;
  flex-direction: column;
}

.home-content__container {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  padding: 2rem;
  -webkit-animation: appear 1.75s ease-in-out;
          animation: appear 1.75s ease-in-out;
}

.logo--home {
  width: 550px;
}

.description__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.description__container .description--home {
  color: rgb(200, 200, 200);
  font-family: "Secular One", sans-serif;
  font-weight: 500;
  -webkit-text-stroke: 1px rgb(30, 30, 30);
  font-size: clamp(1.5rem, 3vw, 2.75rem);
  text-transform: uppercase;
  text-align: center;
}
.description__container button {
  background: transparent;
  padding: 1.5rem;
  margin: 0 auto;
  border: 3px solid rgb(174, 116, 3);
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.description__container button .description__link {
  font-size: 2.25rem;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: rgb(200, 200, 200);
  transition: 0.3s;
  aspect-ratio: 1;
}
.description__container button .arrow1 {
  left: -50%;
}
.description__container button .arrow2 {
  left: 50%;
}
.description__container button:hover .description__link {
  transform: translateX(30px) translateY(-50%);
}

@media (max-width: 900px) {
  .home-content__container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
}
@media (max-width: 650px) {
  .section--home {
    background-image: linear-gradient(rgba(30, 30, 30, 0.8), rgba(30, 30, 30, 0.85)), url("images/homeBg2.jpg");
    background-size: cover;
  }
  .home-content__container {
    grid-template-columns: 1fr;
  }
  .logo--home {
    align-self: start;
    width: 95%;
  }
}
@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slideFromLeft {
  from {
    transform: translateX(-100%) translateY(3px);
  }
  to {
    transform: translateX(50%) translateY(3px);
  }
}
@keyframes slideFromRight {
  from {
    transform: translateX(100%) translateY(-3px);
  }
  to {
    transform: translateX(-50%) translateY(-3px);
  }
}
@keyframes slideFromBottomOpacity {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes blur {
  from {
    filter: blur(15px);
  }
  to {
    filter: blur(0px);
  }
}
@keyframes deliverySlideLeft {
  from {
    transform: translateX(50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes deliverySlideRight {
  from {
    transform: translateX(-300%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.section--delivery {
  background-image: linear-gradient(rgba(30, 30, 30, 0.8), rgba(30, 30, 30, 0.85)), url("images/deliveryBg.jpg");
  background-size: cover;
}

.delivery__container {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  padding: 0.5rem;
}
.delivery__container h1 {
  font-size: clamp(1.25rem, 3vw, 1.75rem);
  text-align: center;
  color: white;
  text-shadow: 0 0 7px orange, 0 0 10px orange, 0 0 21px orange, 0 0 42px goldenrod, 0 0 67px goldenrod;
  -webkit-animation: slideFromBottomOpacity 0.75s ease-in;
          animation: slideFromBottomOpacity 0.75s ease-in;
}
.delivery__container .delivery-towns__list,
.delivery__container h1 {
  margin-bottom: 3rem;
}

.delivery-towns__list {
  max-width: 900px;
  width: 100%;
  padding: 0.5rem 1rem;
  margin: 0 auto;
  border: 3px solid rgb(174, 116, 3);
  border-radius: 20px;
  -webkit-animation: appear 0.75s ease-in;
          animation: appear 0.75s ease-in;
}
.delivery-towns__list .delivery__row {
  font-size: clamp(1.25rem, 3vw, 1.5rem);
  font-weight: 600;
  padding: 0.75rem 0.5rem;
  list-style: none;
  color: rgb(200, 200, 200);
}
.delivery-towns__list .delivery__row:not(.delivery-towns__list .delivery__row:last-child) {
  border-bottom: 1px solid rgb(174, 116, 3);
}
.delivery-towns__list .town {
  display: inline-block;
  width: calc(100% - 80px);
  -webkit-animation: deliverySlideLeft 1s ease-in;
          animation: deliverySlideLeft 1s ease-in;
}
.delivery-towns__list .price {
  text-align: end;
  display: inline-block;
  width: 70px;
  -webkit-animation: deliverySlideRight 1s ease-in;
          animation: deliverySlideRight 1s ease-in;
}

.delivery-icon__container {
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  width: 90%;
  margin: 0 auto;
}
.delivery-icon__container:hover .fa-smog {
  transform: scale(1.1);
}
.delivery-icon__container:hover .fa-car {
  transform: translateX(30px);
}
.delivery-icon__container .fa-icon--delivery {
  font-size: clamp(3rem, 3vw, 5rem);
  transition: 0.3s ease-in-out;
}
.delivery-icon__container .fa-smog {
  color: rgb(200, 200, 200);
}
.delivery-icon__container .fa-car,
.delivery-icon__container .fa-house {
  color: rgb(174, 116, 3);
}
.delivery-icon__container .fa-car {
  margin-left: 0.5rem;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slideFromLeft {
  from {
    transform: translateX(-100%) translateY(3px);
  }
  to {
    transform: translateX(50%) translateY(3px);
  }
}
@keyframes slideFromRight {
  from {
    transform: translateX(100%) translateY(-3px);
  }
  to {
    transform: translateX(-50%) translateY(-3px);
  }
}
@keyframes slideFromBottomOpacity {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes blur {
  from {
    filter: blur(15px);
  }
  to {
    filter: blur(0px);
  }
}
@keyframes deliverySlideLeft {
  from {
    transform: translateX(50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes deliverySlideRight {
  from {
    transform: translateX(-300%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.section--gallery {
  background-color: rgb(30, 30, 30);
}

.gallery {
  display: grid;
  grid-template-areas: "one one two three" "one one two four" "five six six four" "seven eight nine nine" "ten eight nine nine" "ten eleven twelve twelve" "thirteen eleven twelve twelve";
  gap: 1rem;
  padding: 0.5rem;
}

.gallery__image {
  overflow: hidden;
}
.gallery__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
  transition: 0.2s ease-in-out;
  -webkit-animation: blur 1.25s ease-in-out;
          animation: blur 1.25s ease-in-out;
}
.gallery__image img:hover {
  transform: scale(1.08);
  filter: none;
}

.photos {
  color: rgb(200, 200, 200);
  font-family: "Lato", sans-serif;
  font-weight: 600;
  font-size: clamp(1rem, 3vw, 2rem);
  padding: 2rem;
  text-align: center;
}
.photos a {
  color: rgb(200, 200, 200);
  margin: 0.5rem;
  transition: 0.25s;
}
.photos a:hover {
  color: white;
}
.photos a svg {
  margin-right: 0.5rem;
}

@media (max-width: 1200px) {
  .gallery {
    grid-template-areas: "one one two" "one one two" "three six six" "four six six" "four five seven" "four five eight" "nine nine eight" "eleven thirteen thirteen" "ten thirteen thirteen" "ten twelve twelve";
  }
}
@media (max-width: 600px) {
  .gallery {
    grid-template-areas: "one" "two" "three" "four" "five" "six" "seven" "eight" "nine" "ten" "twelve" "eleven" "thirteen";
  }
}
.gallery__image:nth-child(1) {
  grid-area: one;
}

.gallery__image:nth-child(2) {
  grid-area: two;
}

.gallery__image:nth-child(3) {
  grid-area: three;
}

.gallery__image:nth-child(4) {
  grid-area: four;
}

.gallery__image:nth-child(5) {
  grid-area: five;
}

.gallery__image:nth-child(6) {
  grid-area: six;
}

.gallery__image:nth-child(7) {
  grid-area: seven;
}

.gallery__image:nth-child(8) {
  grid-area: eight;
}

.gallery__image:nth-child(9) {
  grid-area: nine;
}

.gallery__image:nth-child(10) {
  grid-area: ten;
}

.gallery__image:nth-child(11) {
  grid-area: eleven;
}

.gallery__image:nth-child(12) {
  grid-area: twelve;
}

.gallery__image:nth-child(13) {
  grid-area: thirteen;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slideFromLeft {
  from {
    transform: translateX(-100%) translateY(3px);
  }
  to {
    transform: translateX(50%) translateY(3px);
  }
}
@keyframes slideFromRight {
  from {
    transform: translateX(100%) translateY(-3px);
  }
  to {
    transform: translateX(-50%) translateY(-3px);
  }
}
@keyframes slideFromBottomOpacity {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes blur {
  from {
    filter: blur(15px);
  }
  to {
    filter: blur(0px);
  }
}
@keyframes deliverySlideLeft {
  from {
    transform: translateX(50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes deliverySlideRight {
  from {
    transform: translateX(-300%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.section--contact {
  background-color: rgb(30, 30, 30);
  color: rgb(200, 200, 200);
  font-family: "Lato", sans-serif;
  padding: 0.5rem;
}

.contact__logo {
  display: grid;
  place-items: center;
  -webkit-animation: appear 1s ease-in;
          animation: appear 1s ease-in;
}
.contact__logo img {
  max-width: 275px;
  position: relative;
  z-index: 999;
}

.contact__logo::before,
.contact__logo::after {
  content: "";
  position: absolute;
  width: 45%;
  height: 5px;
  background-color: rgb(200, 200, 200);
}

.contact__logo::before {
  transform: translateX(50%);
  -webkit-animation: slideFromLeft 0.875s ease-in;
          animation: slideFromLeft 0.875s ease-in;
}

.contact__logo::after {
  transform: translateX(-50%);
  -webkit-animation: slideFromRight 0.875s ease-in;
          animation: slideFromRight 0.875s ease-in;
}

.contact__container {
  display: flex;
  justify-content: space-around;
}

.hours,
.links {
  -webkit-animation: slideFromBottomOpacity 0.75s ease-in;
          animation: slideFromBottomOpacity 0.75s ease-in;
}

.hours {
  font-size: clamp(1.25rem, 3vw, 1.75rem);
  border-bottom: 1px solid rgb(174, 116, 3);
  padding-bottom: 2rem;
}
.hours h1,
.hours .hours__table {
  text-align: center;
  margin: 0 auto;
}
.hours h1 {
  color: rgb(174, 116, 3);
  margin-bottom: 0.5rem;
}
.hours .hours__table {
  width: 500px;
  line-height: 2rem;
  font-weight: 600;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.links {
  width: 100%;
  border-bottom: 1px solid rgb(174, 116, 3);
  font-weight: 600;
}
.links .fa-icon--contact {
  font-size: clamp(2.5rem, 3vw, 3rem);
  filter: grayscale(1);
  transition: 0.25s;
}
.links a {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  color: rgb(200, 200, 200);
  display: block;
  margin-bottom: 1.25rem;
  font-size: clamp(1.75rem, 3vw, 2rem);
  cursor: pointer;
  transition: 0.25s;
}
.links a svg {
  margin-right: 1rem;
}
.links a:hover {
  color: white;
}
.links a:hover svg {
  filter: grayscale(0);
}

.icon--instagram {
  color: rgb(193, 53, 132);
}

.icon--facebook {
  color: rgb(59, 89, 152);
}

.icon--phone {
  color: rgb(174, 116, 3);
}

.map {
  -webkit-animation: slideFromBottomOpacity 0.75s ease-in;
          animation: slideFromBottomOpacity 0.75s ease-in;
}
.map h2 {
  font-size: clamp(2.25rem, 3vw, 2.5rem);
  color: rgb(174, 116, 3);
  text-align: center;
  margin-bottom: 0.5rem;
}

@media (max-width: 1200px) {
  .contact__container {
    flex-direction: column;
  }
  .links {
    border-bottom: none;
  }
  .links a {
    margin: 0 auto 1.25rem auto;
  }
  .map {
    margin-top: 1.5rem;
    text-align: center;
  }
  .map iframe {
    width: 90%;
    margin: 0 auto;
  }
  .hours .hours__table {
    width: auto;
  }
  .hours .hours__table td {
    display: block;
  }
}
.section--menu {
  background-image: linear-gradient(rgba(30, 30, 30, 0.8), rgba(30, 30, 30, 0.85)), url("images/h2.jpg");
  background-size: cover;
  color: rgb(200, 200, 200);
  font-family: "Lato", sans-serif;
}

.categories__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 80%;
  padding: 0.75rem 0;
  border-top: 2px solid rgb(174, 116, 3);
  border-bottom: 2px solid rgb(174, 116, 3);
}
.categories__container div {
  flex: 1;
  text-align: center;
  cursor: pointer;
  padding: 0.5rem;
  transition: 0.25s;
}
.categories__container div:hover {
  color: white;
}
.categories__container div p {
  margin-top: 0.5rem;
  font-weight: 600;
  font-size: clamp(1.25rem, 3vw, 1.5rem);
}
.categories__container div svg {
  font-size: clamp(1.75rem, 4.5vw, 2.5rem);
}

.fries {
  text-align: center;
  font-size: clamp(0.875rem, 3vw, 1.75rem);
  color: rgb(174, 116, 3);
  margin: 2rem 0 1rem 0;
}

ol {
  list-style-type: decimal;
  font-size: clamp(0.875rem, 3vw, 1.5rem);
  width: 75%;
  margin: 0 auto;
}
ol li {
  padding: 0.5rem 0;
  border-bottom: 1px solid rgb(174, 116, 3);
}
ol li::marker,
ol .menu__item--name,
ol .menu__item--price,
ol .pizza--price {
  color: rgb(174, 116, 3);
  font-weight: 600;
}
ol .name-price__container,
ol .ingredients__container {
  display: flex;
}
ol .menu__item--price,
ol .pizza--price {
  text-align: end;
  margin: 0 0 0 auto;
}

@media screen and (orientation: portrait) {
  .section--menu {
    background-image: linear-gradient(rgba(30, 30, 30, 0.8), rgba(30, 30, 30, 0.85)), url("images/v2.jpg");
    background-size: cover;
  }
}
.header__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: transparent;
  padding: 3rem 2rem;
  gap: 1.75rem;
}
.header__navigation li {
  position: relative;
  font-size: clamp(1.25rem, 3vw, 2rem);
  font-family: "Arvo", serif;
  font-weight: 600;
  text-transform: uppercase;
  list-style: none;
  cursor: pointer;
  color: rgb(200, 200, 200);
  transition: 0.3s ease-out;
}
.header__navigation li::after {
  content: "";
  position: absolute;
  background-color: white;
  height: 3px;
  width: 0%;
  bottom: 0;
  right: 0;
  transition: width 0.25s ease-in-out;
}
.header__navigation li:hover {
  color: rgb(255, 255, 255);
}
.header__navigation li:hover::after {
  width: 100%;
  left: 0;
}
.header__navigation .section--active {
  color: white;
  text-shadow: 0 0 7px orange, 0 0 10px orange, 0 0 21px orange, 0 0 42px goldenrod;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

section {
  min-height: 100vh;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}/*# sourceMappingURL=styles.css.map */