@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-100%) translateY(3px);
  }
  to {
    transform: translateX(50%) translateY(3px);
  }
}

@keyframes slideFromRight {
  from {
    transform: translateX(100%) translateY(-3px);
  }
  to {
    transform: translateX(-50%) translateY(-3px);
  }
}

@keyframes slideFromBottomOpacity {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes blur {
  from {
    filter: blur(15px);
  }
  to {
    filter: blur(0px);
  }
}

@keyframes deliverySlideLeft {
  from {
    transform: translateX(50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes deliverySlideRight {
  from {
    transform: translateX(-300%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
