@use "../helpers/variables" as var;
@use "../helpers/mixins" as mix;

.section--menu {
  @include mix.imageGradient(0.8, 0.85, "h2.jpg");
  color: var.$clr--primary;
  font-family: var.$font--content;
}

.categories__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 80%;
  padding: 0.75rem 0;
  border-top: 2px solid var.$clr--accent;
  border-bottom: 2px solid var.$clr--accent;

  div {
    flex: 1;
    text-align: center;
    cursor: pointer;
    padding: 0.5rem;
    transition: 0.25s;

    &:hover {
      color: white;
    }

    p {
      margin-top: 0.5rem;
      font-weight: var.$font--600;
      font-size: clamp(1.25rem, 3vw, 1.5rem);
    }

    svg {
      font-size: clamp(1.75rem, 4.5vw, 2.5rem);
    }
  }
}

.fries {
  text-align: center;
  font-size: clamp(0.875rem, 3vw, 1.75rem);
  color: var.$clr--accent;
  margin: 2rem 0 1rem 0;
}

ol {
  list-style-type: decimal;
  font-size: clamp(0.875rem, 3vw, 1.5rem);
  width: 75%;
  margin: 0 auto;

  li {
    padding: 0.5rem 0;
    border-bottom: 1px solid var.$clr--accent;
  }

  li::marker,
  .menu__item--name,
  .menu__item--price,
  .pizza--price {
    color: var.$clr--accent;
    font-weight: var.$font--600;
  }

  .name-price__container,
  .ingredients__container {
    display: flex;
  }

  .menu__item--price,
  .pizza--price {
    text-align: end;
    margin: 0 0 0 auto;
  }
}

@media screen and (orientation: portrait) {
  .section--menu {
    @include mix.imageGradient(0.8, 0.85, "v2.jpg");
  }
}
