@use "../helpers/mixins" as mix;
@use "../helpers/variables" as var;
@import "../helpers/animations";

.section--delivery {
  @include mix.imageGradient(0.8, 0.85, "deliveryBg.jpg");
}

.delivery__container {
  font-family: var.$font--content;
  text-transform: uppercase;
  padding: 0.5rem;

  h1 {
    font-size: clamp(1.25rem, 3vw, 1.75rem);
    text-align: center;
    color: white;
    text-shadow: 0 0 7px orange, 0 0 10px orange, 0 0 21px orange,
      0 0 42px goldenrod, 0 0 67px goldenrod;
    animation: slideFromBottomOpacity 0.75s ease-in;
  }

  .delivery-towns__list,
  h1 {
    margin-bottom: 3rem;
  }
}

.delivery-towns__list {
  max-width: 900px;
  width: 100%;
  padding: 0.5rem 1rem;
  margin: 0 auto;
  border: 3px solid var.$clr--accent;
  border-radius: 20px;
  animation: appear 0.75s ease-in;

  .delivery__row {
    font-size: clamp(1.25rem, 3vw, 1.5rem);
    font-weight: var.$font--600;
    padding: 0.75rem 0.5rem;
    list-style: none;
    color: var.$clr--primary;

    &:not(&:last-child) {
      border-bottom: 1px solid var.$clr--accent;
    }
  }

  .town {
    display: inline-block;
    width: calc(100% - 80px);
    animation: deliverySlideLeft 1s ease-in;
  }

  .price {
    text-align: end;
    display: inline-block;
    width: 70px;
    animation: deliverySlideRight 1s ease-in;
  }
}

.delivery-icon__container {
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  width: 90%;
  margin: 0 auto;

  &:hover {
    .fa-smog {
      transform: scale(1.1);
    }

    .fa-car {
      transform: translateX(30px);
    }
  }

  .fa-icon--delivery {
    font-size: clamp(3rem, 3vw, 5rem);
    transition: 0.3s ease-in-out;
  }

  .fa-smog {
    color: var.$clr--primary;
  }

  .fa-car,
  .fa-house {
    color: var.$clr--accent;
  }

  .fa-car {
    margin-left: 0.5rem;
  }
}
