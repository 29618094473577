@use "../helpers/mixins" as mix;
@use "../helpers/variables" as var;
@import "../helpers/animations";

.section--home {
  @include mix.imageGradient(0.8, 0.85, "homeBg.jpg");
  display: flex;
  flex-direction: column;
}

.home-content__container {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  place-items: center;
  padding: 2rem;
  animation: appear 1.75s ease-in-out;
}

.logo--home {
  width: 550px;
}

.description__container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .description--home {
    color: var.$clr--primary;
    font-family: var.$font--description;
    font-weight: var.$font--500;
    -webkit-text-stroke: 1px var.$clr--secondary;
    font-size: clamp(1.5rem, 3vw, 2.75rem);
    text-transform: uppercase;
    text-align: center;
  }

  button {
    background: transparent;
    padding: 1.5rem;
    margin: 0 auto;
    border: 3px solid var.$clr--accent;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    .description__link {
      font-size: 2.25rem;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      color: var.$clr--primary;
      transition: 0.3s;
      aspect-ratio: 1;
    }

    .arrow1 {
      left: -50%;
    }

    .arrow2 {
      left: 50%;
    }
  }

  button:hover .description__link {
    transform: translateX(30px) translateY(-50%);
  }
}

@media (max-width: 900px) {
  .home-content__container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
}

@media (max-width: 650px) {
  .section--home {
    @include mix.imageGradient(0.8, 0.85, "homeBg2.jpg");
  }
  .home-content__container {
    grid-template-columns: 1fr;
  }
  .logo--home {
    align-self: start;
    width: 95%;
  }
}
